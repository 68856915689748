import { createApp } from "vue";
import Vuex from "vuex";
import App from "../App.vue";
//import auth from "./modules/auth";
import auth from "./Auth/login";
import sale_type from "./SaleSetting/SaleType";
import sale_platform from "./SaleSetting/SalePlatform";
import sale_city from "./SaleSetting/SaleCity";
import item_unit from "./ItemSetting/ItemUnit";
import item_category from "./ItemSetting/ItemCategory";
import item_waste_category from "./ItemSetting/ItemWasteCategory";
import expense_category from "./Expense/ExpenseCategory";
import income_category from "./Income/IncomeCategory";
import dolar_price from "./DolarPrice/DolarPrice";
import note from "./Note/Note";
import all_box from "./Box/AllBox";
import exchange_box from "./Box/ExchangeBox";
import navbar from "./Navbar/Navbar";
import transfer_box from "./Box/TransferBox";
import customer from "./Customer/Customer";
import suplier from "./Suplier/Suplier";
import add_expense from "./Expense/AddExpense";
import add_income from "./Income/AddIncome";
import expense_list from "./Expense/ExpenseList";
import income_list from "./Income/IncomeList";
import add_debt from "./Debt/AddDebt";
import debt_list from "./Debt/DebtList";
import add_item from "./Item/AddItem";
import item_list from "./Item/ItemList";
import shortage_item from "./Item/ShortageItem";
import expire_item from "./Item/ExpireItem";
import waste_item from "./Item/WasteItem";
import sale_pos from "./Sale/SalePos";
import edit_sale from "./Sale/EditSale";
//ReturnInvoice
import returns_invoice from "./Sale/ReturnsInvoice";
import sold_list from "./Sale/SoldList";
import category_report from "./Report/CategoryReport";
import city_report from "./Report/CityReport";
import platform_report from "./Report/PlatformReport";
import sale_report from "./Report/SaleReport";
import employee_report from "./Report/EmployeeReport";
import item_report from "./Report/ItemReport";
import customer_report from "./Report/CustomerReport";
import waste_category_report from "./Report/WasteCategoryReport";
import waste_item_report from "./Report/WasteItemReport";
import expense_category_report from "./Report/ExpenseCategoryReport";
import expense_customer_report from "./Report/ExpenseCustomerReport";
//income
import income_customer_report from "./Report/IncomeCustomerReport";
import income_category_report from "./Report/IncomeCategoryReport";
import debt_report from "./Report/DebtReport";
import suplier_report from "./Report/SuplierReport";
import purchase_item_report from "./Report/PurchaseItemReport";
//CustomerAccount
import customer_account from "./Customer/CustomerAccount";
import suplier_account from "./Suplier/SuplierAccount";
import new_purchase from "./Purchase/NewPurchase";
import purchase_list from "./Purchase/PurchaseList";
import edit_purchase from "./Purchase/EditPurchase";
import all_yearly_report from "./Report/AllYearlyReport";
import change_password from "./ChangePassword/ChangePassword";
import item_detail from "./Item/ItemDetail";
import return_purchases from "./Purchase/ReturnPurchases";
import new_return_purchase from "./Purchase/NewReturnPurchase";
import edit_return_purchase from "./Purchase/EditReturnPurchase";
import settings from "./Settings/Settings";
import employee from "./Settings/Employee";
import role from "./Settings/Role";
import box_detail from "./Box/BoxDetail";
import add_role from "./Role/AddRole";
//role_list
import role_list from "./Role/RoleList";
//Home
import home from "./Home/Home";
//HistoryLog
import history_log from "./HistoryLog/HistoryLog";
import { FILE_URL } from "../constants/config";
const app = createApp(App);
app.use(Vuex);
const store = new Vuex.Store({
  modules: {
    auth: auth,
    sale_type: sale_type,
    sale_platform: sale_platform,
    sale_city: sale_city,
    item_unit: item_unit,
    item_category: item_category,
    item_waste_category: item_waste_category,
    expense_category: expense_category,
    income_category: income_category,
    dolar_price: dolar_price,
    note: note,
    all_box: all_box,
    exchange_box: exchange_box,
    transfer_box: transfer_box,
    navbar: navbar,
    customer: customer,
    suplier: suplier,
    add_expense: add_expense,
    add_income: add_income,
    expense_list: expense_list,
    income_list: income_list,
    add_debt: add_debt,
    debt_list: debt_list,
    add_item: add_item,
    item_list: item_list,
    shortage_item: shortage_item,
    expire_item: expire_item,
    waste_item: waste_item,
    sale_pos: sale_pos,
    edit_sale: edit_sale,
    returns_invoice: returns_invoice,
    sold_list: sold_list,
    category_report: category_report,
    city_report: city_report,
    platform_report: platform_report,
    sale_report: sale_report,
    employee_report: employee_report,
    item_report: item_report,
    customer_report: customer_report,
    waste_category_report: waste_category_report,
    waste_item_report: waste_item_report,
    expense_category_report: expense_category_report,
    expense_customer_report: expense_customer_report,
    income_customer_report: income_customer_report,
    income_category_report: income_category_report,
    debt_report: debt_report,
    suplier_report: suplier_report,
    purchase_item_report: purchase_item_report,
    customer_account: customer_account,
    suplier_account: suplier_account,
    new_purchase: new_purchase,
    purchase_list: purchase_list,
    edit_purchase: edit_purchase,
    all_yearly_report: all_yearly_report,
    change_password: change_password,
    item_detail: item_detail,
    return_purchases: return_purchases,
    new_return_purchase: new_return_purchase,
    edit_return_purchase: edit_return_purchase,
    settings: settings,
    employee: employee,
    role: role,
    box_detail: box_detail,
    home: home,
    add_role: add_role,
    role_list: role_list,
    history_log: history_log,
  },
  // You can also define global state, getters, mutations, and actions here
  state: {
    // Global state properties
    theme: localStorage.getItem("theme"),
    loading: false,
  },
  getters: {
    // Global getters
    //get language from local storage
    getTheme: (state) => state.theme,
    getLoading: (state) => state.loading,
    //get language from local storage

    isRtl() {
      return (
        localStorage.getItem("lang_wise_manager") == "ar" ||
        localStorage.getItem("lang_wise_manager") == "ku" ||
        //if null
        localStorage.getItem("lang_wise_manager") == null ||
        //if empty
        localStorage.getItem("lang_wise_manager") == ""
      );
    },
    //get current date
    date() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    //get current time
    time() {
      var today = new Date();
      var hh = String(today.getHours()).padStart(2, "0");
      var mm = String(today.getMinutes()).padStart(2, "0");
      var ss = String(today.getSeconds()).padStart(2, "0");
      today = hh + ":" + mm + ":" + ss;
      return today;
    },
    //get current logined user
    user() {
      return JSON.parse(localStorage.getItem("user_wise_manager"));
    },
    //checkUserAccess by role by id and action
    // eslint-disable-next-line no-unused-vars
    checkUserAccess: (state) => (payload) => {
      var user = JSON.parse(localStorage.getItem("user_wise_manager"));
      console.log('test');
      console.log(user);

      var roles = {};
      try {
        roles = user.role.ro_access.roles;
      } catch (e) {
        console.log(e);
       // window.location.href = "/login";
      }

      //loop inside roles array check if id is exist then check if action view or add or edit or delete or detail check this field
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].id == payload.id) {
          if (payload.action == "view") {
            return roles[i].view;
          } else if (payload.action == "add") {
            return roles[i].add;
          } else if (payload.action == "edit") {
            return roles[i].edit;
          } else if (payload.action == "delete") {
            return roles[i].delete;
          } else if (payload.action == "detail") {
            return roles[i].detail;
          }
        }
      }
      return false;
    },
    //check tutorial access
    // eslint-disable-next-line no-unused-vars
    checkTutorialAccess() {
      var user = JSON.parse(localStorage.getItem("user_wise_manager"));

      var hasAccess = user.role.ro_access.tutorial;
      return hasAccess;
    },
    checkPrintAccess() {
      var user = JSON.parse(localStorage.getItem("user_wise_manager"));

      var hasAccess = user.role.ro_access.print;
      return hasAccess;
    },
    //currentSettingWiseManager
    currentSettingWiseManager() {
      return JSON.parse(localStorage.getItem("currentSettingWiseManager"));
    },
    //get file url
    fileUrl() {
      return FILE_URL;
    },
  },
  mutations: {
    // Global mutations
  },
  actions: {
    // Global actions
    //checkUserAccess by role by id and action
    // eslint-disable-next-line no-unused-vars
    checkUserAccess({ commit }, payload) {
      var user = JSON.parse(localStorage.getItem("user_wise_manager"));
      var roles = user.role.ro_access.roles;

      //loop inside roles array check if id is exist then check if action view or add or edit or delete or detail check this field
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].id == payload.id) {
          if (payload.action == "view") {
            return roles[i].view;
          } else if (payload.action == "add") {
            return roles[i].add;
          } else if (payload.action == "edit") {
            return roles[i].edit;
          } else if (payload.action == "delete") {
            return roles[i].delete;
          } else if (payload.action == "detail") {
            return roles[i].detail;
          }
        }
      }
      return false;
    },
  },
});

export default store;
